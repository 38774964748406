<div class="modal-header">
  <h4 class="modal-title">Password modification</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="userForm" (ngSubmit)="onFormSubmit()" id="ngForm">

    <div class="row">
      <div class="col-md-12">
        <label class="form-label">Password <span class="text-danger">*</span></label>
        <input class="form-control form-control-sm bg-gradient" type="password" placeholder="********" formControlName="password" [ngClass]="{'is-invalid': fc['password'].invalid && isSubmitted}">
        <small class="invalid-feedback fst-italic" *ngIf="fc['password'].errors?.['required']">This field is required</small>
        <div class="invalid-feedback" *ngIf="fc['password'].errors?.['minlength']">Password must be atleast 3 caracters long</div>
        <div class="invalid-feedback" *ngIf="fc['password'].errors?.['serverError']">
          {{ fc['password'].errors?.['serverError'] }}
        </div>
      </div>
    </div>

  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-sm btn-outline-dark" (click)="activeModal.close('Close click')">Close</button>

  <button form="ngForm" (submit)="onFormSubmit()" type="submit" class="btn btn-sm btn-outline-primary" [disabled]=saving>
    <ng-container *ngIf="!saving; else isSaving">{{data ? update : create}}</ng-container>
    <ng-template #isSaving>
      {{ "Submitting" | translate}} ...
    </ng-template>
  </button>

</div>














