<div class="media profile-media">
<!--  <ng-template *ngIf="user['picture']; else secondImg">-->
<!--    hhhhhhhh-->
<!--    <img [src]="userPicture"  class="b-r-10" alt="" height="35" width="35">-->
<!--  </ng-template>-->

<!--  <ng-template #secondImg>-->
<!--    ggggg-->
<!--    <img class="b-r-10" src="assets/images/dashboard/profile.png" alt="">-->
<!--  </ng-template>-->

  <img [src]="user['picture'] ? userPicture : 'assets/images/dashboard/profile.png'"  class="b-r-10" alt="" height="35" width="35">


  <div class="media-body"><span *ngIf="this.authService.isLoggedIn">{{userName}}</span>
    <p class="mb-0 font-roboto">{{ userProfile ? userProfile : 'NaN' }} <i class="middle fa fa-angle-down"></i></p>
  </div>
</div>
<ul class="profile-dropdown onhover-show-div">
    <li><a (click)="updatePassword(this.user)"><app-feather-icons [icon]="'lock'"></app-feather-icons><span>Password</span></a></li>

  <!--  <li><a href="/user/profile"><app-feather-icons [icon]="'user'"></app-feather-icons><span>Account </span></a></li>-->
<!--  <li><a href="email"><app-feather-icons [icon]="'mail'"></app-feather-icons><span>Inbox</span></a></li>-->
<!--  <li><a href="/to-do"><app-feather-icons [icon]="'file-text'"></app-feather-icons><span>Taskboard</span></a></li>-->
<!--  <li><a href="/faq"><app-feather-icons [icon]="'settings'"></app-feather-icons><span>Settings</span></a></li>-->
  <li (click)="logoutFunc()" *ngIf="this.authService.isLoggedIn"><app-feather-icons [icon]="'log-in'"></app-feather-icons><span>Log out</span></li>
</ul>
