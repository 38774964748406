<!--<div class="container-fluid p-0">-->
<!--  <div class="row">-->
<!--    <div class="col-12">-->
<!--      <div class="login-card">-->
<!--        <div>-->
<!--          <div>-->
<!--            <a class="logo" routerLink="/">-->
<!--              <img class="img-fluid for-light" src="assets/images/logo/logo.png" alt="looginpage" />-->
<!--              <img class="img-fluid for-dark" src="assets/images/logo/logo_dark.png" alt="looginpage" />-->
<!--            </a>-->
<!--          </div>-->
<!--          <div class="login-main">-->
<!--            <form class="theme-form" [formGroup]="loginForm">-->
<!--              <h4>Sign in to account</h4>-->
<!--              <p>Enter your email & password to login</p>-->
<!--              <div class="form-group">-->
<!--                <label class="col-form-label">Email Address</label>-->
<!--                <input class="form-control form-control-sm" type="email" required="" placeholder="app@yourmail.com" formControlName="email" />-->
<!--                <div *ngIf="loginForm.controls.email.touched && loginForm.controls.email.errors?.required" class="text text-danger mt-1">Email is required</div>-->
<!--                <div *ngIf="loginForm.controls.email.touched && loginForm.controls.email.errors?.email" class="text text-danger mt-1">Invalid Email</div>-->
<!--              </div>-->
<!--              <div class="form-group">-->
<!--                <label class="col-form-label">Password</label>-->
<!--                <input class="form-control" [type]="show ? 'text' : 'password'" formControlName="password" required="" placeholder="*********" />-->
<!--                <div class="show-hide" (click)="showPassword()" *ngIf="!show"><span class="show"></span></div>-->
<!--                <div class="show-hide" (click)="showPassword()" *ngIf="show"><span class="Hide"></span></div>-->
<!--                <div *ngIf="loginForm.controls.password.touched && loginForm.controls.password.errors?.required" class="text text-danger mt-1">Password is required</div>-->
<!--              </div>-->
<!--              <div class="form-group mb-0">-->
<!--                <div class="checkbox p-0">-->
<!--                  <input id="checkbox1" type="checkbox" />-->
<!--                  <label class="text-muted" for="checkbox1">Remember password</label>-->
<!--                </div>-->
<!--                <a [routerLink]="'/authentication/forgot-password'" class="link">Forgot password?</a>-->
<!--                <div class="text-end mt-3">-->
<!--                  <button class="btn btn-primary d-block w-100" [disabled]="!loginForm.valid" (click)="login()" type="button"><span>Sign in</span></button>-->
<!--                </div>-->
<!--              </div>-->
<!--              <h6 class="text-muted mt-4 or">Or Sign in with</h6>-->
<!--              <div class="social mt-4">-->
<!--                <div class="btn-showcase">-->
<!--                  <a class="btn btn-light" target="_blank"> <app-feather-icons [icon]="'log-in'" class="txt-google"></app-feather-icons>Google + </a>-->
<!--                  <a class="btn btn-light" target="_blank"> <app-feather-icons [icon]="'facebook'" class="txt-fb"></app-feather-icons>Facebook </a>-->
<!--                  <a class="btn btn-light" target="_blank"> <app-feather-icons [icon]="'twitter'" class="txt-twitter"></app-feather-icons>twitter </a>-->
<!--                </div>-->
<!--              </div>-->
<!--              <p class="mt-4 mb-0">-->
<!--                Don't have account?-->
<!--                <a [routerLink]="'/authentication/register/simple'" class="ms-2">Create Account</a>-->
<!--              </p>-->
<!--            </form>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->




<div class="container-fluid">
  <div class="row">
    <div class="col-xl-7" [ngStyle]="{'background-image': 'url(assets/images/login/2.jpg)',
                                     'background-size': 'cover',
                                     'background-position': 'center center',
                                     'display': 'block'}">
      <img class="bg-img-cover bg-center d-none" src="assets/images/login/2.jpg" alt="looginpage">
    </div>

    <div class="col-xl-5 p-0">
      <div class="login-card">
        <div>
          <div>
            <a class="logo text-start" href="#">
              <img class="img-fluid for-light" src="assets/images/logo/logo-global-erp-blue-orange.png" height="50" width="222" alt="looginpage" >
              <img class="img-fluid for-dark" src="assets/images/logo/logo-global-erp-black-white.png" height="35" width="122" alt="looginpage">
            </a>
          </div>
          <div class="login-main">

<!--            <select (change)="change()" [(ngModel)]="activeLocale">-->
<!--              <option *ngFor="let locale of locales" [value]="locale.code">-->
<!--                {{locale.name}}-->
<!--              </option>-->
<!--            </select>-->

<!--            <ul>-->
<!--              <li *ngFor="let locale of locales">-->
<!--                <a href="/{{locale.code}}">-->
<!--                  {{locale.name}}-->
<!--                </a>-->
<!--              </li>-->
<!--            </ul>-->

            <form class="theme-form" [formGroup]="signinForm" (ngSubmit)="loginUser()">
              <h4>Sign in to account</h4>
              <p>Enter your email & password to login</p>
              <div class="form-group">
                <label class="col-form-label" i18n>Username or Email Address</label>
                <input
                        formControlName="username"
                        class="form-control"
                        type="email"
                        required=""
                        placeholder="app@yourmail.com">
              </div>
              <div class="form-group">
                <label class="col-form-label" i18n>Password</label>
                <input
                        formControlName="password"
                        class="form-control"
                        [type]="show ? 'text' : 'password'"
                        name="login[password]"
                        required=""
                        placeholder="*********">
                <div class="show-hide" (click)="showPassword()" *ngIf="!show"><span class="show"></span></div>
                <div class="show-hide" (click)="showPassword()" *ngIf="show"><span class="Hide"></span></div>
              </div>
              <div class="form-group mb-0">
<!--                <div class="checkbox p-0">-->
<!--                  <input id="checkbox1" type="checkbox">-->
<!--                  <label class="text-muted" style="color: black !important;" for="checkbox1">Remember password</label>-->
<!--                </div>-->
<!--                <a [routerLink]="'/authentication/forgot-password'" class="link">Forgot password?</a>-->
<!--                <button class="btn btn-primary d-block w-100" [disabled]="!loginForm.valid" (click)="login()" type="button">Sign in</button>-->
                <button type="submit" class="btn btn-primary w-100" tabindex="4" [disabled]=login i18n>
                  <ng-container *ngIf="!login; else isLogin">Sign in</ng-container>
                  <ng-template #isLogin>
                    <button class="btn btn-primary"><i class="fa fa-spin fa-spinner me-2"></i>Loading...</button>
                  </ng-template>
                </button>
              </div>
<!--              <h6 class="text-muted mt-4 or">Or Sign in with</h6>-->
<!--              <div class="social mt-4">-->
<!--                <div class="btn-showcase">-->
<!--                  <a class="btn btn-light" href="https://www.linkedin.com/login" target="_blank">-->
<!--                    <app-feather-icons [icon]="'linkedin'" class="txt-linkedin"></app-feather-icons> LinkedIn-->
<!--                  </a>-->
<!--                  <a class="btn btn-light" href="https://twitter.com/login?lang=en" target="_blank">-->
<!--                    <app-feather-icons [icon]="'twitter'" class="txt-twitter"></app-feather-icons>twitter-->
<!--                  </a>-->
<!--                  <a class="btn btn-light" href="https://www.facebook.com/" target="_blank">-->
<!--                    <app-feather-icons [icon]="'facebook'" class="txt-fb"></app-feather-icons>facebook-->
<!--                  </a>-->
<!--                </div>-->
<!--              </div>-->
<!--              <p class="mt-4 mb-0">Don't have account?-->
<!--                <a [routerLink]="'/authentication/register/simple'" class="ms-2">Create Account</a>-->
<!--              </p>-->
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
